import SessionInspector from '../pages/Admin/SessionInspector';

function SessionInspectorRoute() {
  return <SessionInspector />;
}

export const Component = SessionInspectorRoute;

export function clientLoader() {
  return null;
}
